import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useLanguage } from '../../context/LanguageContext';
import volvoLogo from '../../assets/images/brands/volvo.png';
import baudouinLogo from '../../assets/images/brands/baudouin.png';
import SectionTitle from '../SectionTitle';

// Brand Showcase Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

// Brand Showcase Styles
const ProductsContainer = styled.div`
  padding: 5rem calc((100vw - 1200px) / 2);
  margin-top: 0.5rem;
`;

const ProductsWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`;

const Title = styled.h2`
  text-align: center;
  color: #00813C;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: #E31E24;
  }
`;

const TitleWrapper = styled.div`
  margin-top: 0.5rem;
`;

const BrandsSection = styled.div`
  margin-top: 4rem;
`;

const BrandsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  margin-top: 2rem;
`;

const BrandCard = styled.div`
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  animation: ${fadeIn} 0.6s ease-out;
  text-align: center;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  }
`;

const BrandLogo = styled.img`
  height: 60px;
  width: auto;
  margin-bottom: 1.5rem;
  animation: ${float} 3s ease-in-out infinite;

  &[alt="Volvo Penta"] {
    height: 45px;
  }
`;

const VolvoLogo = styled(BrandLogo)`
  height: 45px;
`;

const BrandTitle = styled.h3`
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const BrandDescription = styled.p`
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
`;

const FeatureItem = styled.li`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  color: #555;

  &:before {
    content: '✓';
    color: #00813C;
    margin-right: 0.5rem;
    font-weight: bold;
  }
`;

interface ProductSpec {
  power: string;
  control: {
    [key: string]: string;
  };
  eco: string;
}

interface Product {
  title: {
    [key: string]: string;
  };
  range: string;
  specs: ProductSpec;
}

interface Range {
  id: string;
  label: string | {
    [key: string]: string;
  };
}

interface Translations {
  en: {
    title: string;
    subtitle: string;
  };
  fr: {
    title: string;
    subtitle: string;
  };
  ar: {
    title: string;
    subtitle: string;
  };
  products: Product[];
  ranges: Range[];
  volvoDescription: {
    [key: string]: string;
  };
  volvoFeatures: {
    [key: string]: string[];
  };
  baudouinDescription: {
    [key: string]: string;
  };
  baudouinFeatures: {
    [key: string]: string[];
  };
}

const translations: Translations = {
  en: {
    title: "Our Products",
    subtitle: "Discover our range of high-quality power solutions"
  },
  fr: {
    title: "Nos Produits",
    subtitle: "Découvrez notre gamme de solutions énergétiques de haute qualité"
  },
  ar: {
    title: "منتجاتنا",
    subtitle: "اكتشف مجموعتنا من حلول الطاقة عالية الجودة"
  },
  products: [
    {
      title: {
        en: "20-85 kVA Generator Set",
        fr: "Groupe Électrogène 20-85 kVA",
        ar: "مولد كهربائي 20-85 ك.ف.أ"
      },
      range: "small",
      specs: {
        power: "20-85 kVA",
        control: {
          en: "Digital Control Panel",
          fr: "Panneau de Contrôle Numérique",
          ar: "لوحة تحكم رقمية"
        },
        eco: "Tier 3"
      }
    },
    {
      title: {
        en: "100-250 kVA Generator Set",
        fr: "Groupe Électrogène 100-250 kVA",
        ar: "مولد كهربائي 100-250 ك.ف.أ"
      },
      range: "medium",
      specs: {
        power: "100-250 kVA",
        control: {
          en: "Advanced Digital Control",
          fr: "Contrôle Numérique Avancé",
          ar: "تحكم رقمي متقدم"
        },
        eco: "Tier 3"
      }
    },
    {
      title: {
        en: "300-750 kVA Generator Set",
        fr: "Groupe Électrogène 300-750 kVA",
        ar: "مولد كهربائي 300-750 ك.ف.أ"
      },
      range: "large",
      specs: {
        power: "300-750 kVA",
        control: {
          en: "Premium Control System",
          fr: "Système de Contrôle Premium",
          ar: "نظام تحكم متميز"
        },
        eco: "Tier 3"
      }
    },
    {
      title: {
        en: "800-2000 kVA Generator Set",
        fr: "Groupe Électrogène 800-2000 kVA",
        ar: "مولد كهربائي 800-2000 ك.ف.أ"
      },
      range: "industrial",
      specs: {
        power: "800-2000 kVA",
        control: {
          en: "Industrial Control System",
          fr: "Système de Contrôle Industriel",
          ar: "نظام تحكم صناعي"
        },
        eco: "Tier 3"
      }
    }
  ],
  ranges: [
    {
      id: 'all',
      label: {
        en: 'All Ranges',
        fr: 'Toutes les Gammes',
        ar: 'جميع النطاقات'
      }
    },
    {
      id: 'small',
      label: {
        en: '20-85 kVA',
        fr: '20-85 kVA',
        ar: '20-85 ك.ف.أ'
      }
    },
    {
      id: 'medium',
      label: {
        en: '100-250 kVA',
        fr: '100-250 kVA',
        ar: '100-250 ك.ف.أ'
      }
    },
    {
      id: 'large',
      label: {
        en: '300-750 kVA',
        fr: '300-750 kVA',
        ar: '300-750 ك.ف.أ'
      }
    },
    {
      id: 'industrial',
      label: {
        en: '800-2000 kVA',
        fr: '800-2000 kVA',
        ar: '800-2000 ك.ف.أ'
      }
    }
  ],
  volvoDescription: {
    en: "World-renowned for reliability and performance, Volvo Penta engines represent the pinnacle of power generation technology.",
    fr: "Mondialement reconnu pour sa fiabilité et ses performances, les moteurs Volvo Penta représentent le summum de la technologie de production d'énergie.",
    ar: "معروفة عالمياً بموثوقيتها وأدائها، تمثل محركات فولفو بنتا قمة تكنولوجيا توليد الطاقة."
  },
  volvoFeatures: {
    en: [
      "Superior fuel efficiency",
      "Low emission standards",
      "Advanced electronic control",
      "Robust construction"
    ],
    fr: [
      "Efficacité énergétique supérieure",
      "Faibles émissions",
      "Contrôle électronique avancé",
      "Construction robuste"
    ],
    ar: [
      "كفاءة عالية في استهلاك الوقود",
      "معايير انبعاثات منخفضة",
      "تحكم إلكتروني متقدم",
      "بناء متين"
    ]
  },
  baudouinDescription: {
    en: "With over 100 years of experience, Baudouin delivers reliable and efficient power solutions for critical applications.",
    fr: "Avec plus de 100 ans d'expérience, Baudouin fournit des solutions énergétiques fiables et efficaces pour les applications critiques.",
    ar: "مع أكثر من 100 عام من الخبرة، تقدم بودوان حلول طاقة موثوقة وفعالة للتطبيقات الحساسة."
  },
  baudouinFeatures: {
    en: [
      "High power density",
      "Excellent load acceptance",
      "Durable design",
      "Global service network"
    ],
    fr: [
      "Haute densité de puissance",
      "Excellente acceptation de charge",
      "Conception durable",
      "Réseau de service mondial"
    ],
    ar: [
      "كثافة طاقة عالية",
      "قبول ممتاز للحمل",
      "تصميم متين",
      "شبكة خدمة عالمية"
    ]
  }
};

const Products: React.FC = () => {
  const { language } = useLanguage();

  return (
    <section className={`products-section ${language === 'ar' ? 'rtl' : ''}`}>
      <ProductsContainer>
        <TitleWrapper>
          <SectionTitle 
            title={translations[language].title}
            subtitle={translations[language].subtitle}
          />
        </TitleWrapper>
        <ProductsWrapper>
          {/* Brand Showcase Section */}
          <BrandsSection>
            <BrandsGrid>
              <BrandCard>
                <VolvoLogo src={volvoLogo} alt="Volvo Penta" />
                <BrandTitle>Volvo Penta</BrandTitle>
                <BrandDescription>
                  {translations.volvoDescription[language]}
                </BrandDescription>
                <FeaturesList>
                  {translations.volvoFeatures[language].map((feature: string, index: number) => (
                    <FeatureItem key={index}>{feature}</FeatureItem>
                  ))}
                </FeaturesList>
              </BrandCard>

              <BrandCard>
                <BrandLogo src={baudouinLogo} alt="Baudouin" />
                <BrandTitle>Baudouin</BrandTitle>
                <BrandDescription>
                  {translations.baudouinDescription[language]}
                </BrandDescription>
                <FeaturesList>
                  {translations.baudouinFeatures[language].map((feature: string, index: number) => (
                    <FeatureItem key={index}>{feature}</FeatureItem>
                  ))}
                </FeaturesList>
              </BrandCard>
            </BrandsGrid>
          </BrandsSection>
        </ProductsWrapper>
      </ProductsContainer>
    </section>
  );
};

export default Products;