import React from 'react';
import { motion } from 'framer-motion';
import './PartnersSection.css';
import SectionTitle from '../SectionTitle';

// Import all partner logos
import volvoLogo from '../../assets/images/brands/volvo.png';
import baudouinLogo from '../../assets/images/brands/baudouin.png';
import leroySomerLogo from '../../assets/images/brands/leroy-somer.png';
import meccalteLogo from '../../assets/images/brands/meccalte.png';
import socomecLogo from '../../assets/images/brands/socomec.png';
import schneiderLogo from '../../assets/images/brands/schneider.png';
import comapLogo from '../../assets/images/brands/comap.png';
import linzLogo from '../../assets/images/brands/linz.png';
import abbLogo from '../../assets/images/brands/abb.png';

interface PartnersSectionProps {
  language: 'en' | 'fr' | 'ar';
}

const translations = {
  en: {
    title: 'Our Trusted Partners',
    subtitle: 'Working with global leaders in power generation'
  },
  fr: {
    title: 'Nos Partenaires de Confiance',
    subtitle: 'Collaboration avec les leaders mondiaux de la production d\'énergie'
  },
  ar: {
    title: 'شركاؤنا الموثوقون',
    subtitle: 'نعمل مع الشركات الرائدة عالمياً في مجال توليد الطاقة'
  }
};

const partners = [
  { name: 'Volvo Penta', logo: volvoLogo },
  { name: 'Baudouin', logo: baudouinLogo },
  { name: 'Leroy Somer', logo: leroySomerLogo },
  { name: 'Mecc Alte', logo: meccalteLogo },
  { name: 'Socomec', logo: socomecLogo },
  { 
    name: 'Schneider Electric',
    logo: schneiderLogo,
    scale: 1.4
  },
  { name: 'ComAp', logo: comapLogo },
  { name: 'Linz Electric', logo: linzLogo },
  { name: 'ABB', logo: abbLogo }
];

const PartnersSection: React.FC<PartnersSectionProps> = ({ language }) => {
  const t = translations[language];

  return (
    <div className={`partners-section ${language === 'ar' ? 'rtl' : 'ltr'}`}>
      <motion.div 
        className="partners-content"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <SectionTitle 
          title={t.title}
          subtitle={t.subtitle}
        />

        <div className="partners-grid">
          {partners.map((partner, index) => (
            <motion.div
              key={partner.name}
              className="partner-logo-container"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.5,
                delay: index * 0.1
              }}
              whileHover={{ scale: 1.05 }}
            >
              <img 
                src={partner.logo} 
                alt={partner.name}
                className="partner-logo"
                style={partner.scale ? { 
                  maxWidth: `${partner.scale * 80}%`,
                  maxHeight: `${partner.scale * 80}%`
                } : undefined}
              />
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default PartnersSection; 