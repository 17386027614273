import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { useLanguage } from '../../context/LanguageContext';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styled from 'styled-components';

// Import images
import slide1 from '../../assets/images/slider/pct1.jpg';
import slide2 from '../../assets/images/slider/pct2.jpeg';
import slide3 from '../../assets/images/slider/pct3.jpeg';
import slide4 from '../../assets/images/slider/pct4.jpeg';
import slide5 from '../../assets/images/slider/pct5.jpeg';
import slide6 from '../../assets/images/slider/pct.jpeg';

const SliderContainer = styled.div`
  width: 100%;
  height: 600px;
  margin-top: 0;
  position: relative;
  z-index: 2;
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-slide {
    text-align: center;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
  }

  .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
  }

  .swiper-slide-active img {
    transform: scale(1);
  }

  .swiper-slide-prev img,
  .swiper-slide-next img {
    transform: scale(0.95);
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    padding: 2rem;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    transition: all 0.3s ease;
    
    &:hover {
      background: rgba(0, 0, 0, 0.9);
    }
    
    &::after {
      font-size: 1rem;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    background: #fff;
    opacity: 0.7;
    
    &-active {
      background: #057707;
      opacity: 1;
    }
  }

  &[dir="rtl"] {
    .swiper-button-next {
      transform: scaleX(-1);
      left: 10px;
      right: auto;
    }
    .swiper-button-prev {
      transform: scaleX(-1);
      right: 10px;
      left: auto;
    }
  }
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
`;

const SlideContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
`;

interface SlideType {
  id: number;
  image: string;
  alt: string;
}

const Slider: React.FC = () => {
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  const slides: SlideType[] = [
    {
      id: 1,
      image: slide1,
      alt: 'GMI Industrial Solutions 1'
    },
    {
      id: 2,
      image: slide2,
      alt: 'GMI Industrial Solutions 2'
    },
    {
      id: 3,
      image: slide3,
      alt: 'GMI Industrial Solutions 3'
    },
    {
      id: 4,
      image: slide4,
      alt: 'GMI Industrial Solutions 4'
    },
    {
      id: 5,
      image: slide5,
      alt: 'GMI Industrial Solutions 5'
    },
    {
      id: 6,
      image: slide6,
      alt: 'GMI Industrial Solutions 6'
    }
  ];

  return (
    <SliderContainer>
      <StyledSwiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        loop={true}
        speed={800}
        effect="fade"
        fadeEffect={{
          crossFade: true
        }}
        slidesPerView={1}
        spaceBetween={0}
        dir={isRTL ? 'rtl' : 'ltr'}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <SlideContainer>
              <SlideImage src={slide.image} alt={slide.alt} loading="eager" />
            </SlideContainer>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </SliderContainer>
  );
};

export default Slider;
