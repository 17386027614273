import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaClock, FaWhatsapp, FaFacebook, FaLinkedin } from 'react-icons/fa';
import { useLanguage } from '../../context/LanguageContext';
import emailjs from '@emailjs/browser';
import './Contact.css';
import SectionTitle from '../SectionTitle';

const translations = {
  en: {
    title: "Get In Touch",
    subtitle: "We're here to help and answer any questions you might have",
    form: {
      name: 'Full Name',
      email: 'Email Address',
      phone: 'Phone Number',
      subject: 'Subject',
      message: 'Your Message',
      send: 'Send Message'
    },
    info: {
      phone: 'Phone',
      phoneDesc: 'Call us directly for immediate assistance',
      email: 'Email',
      emailDesc: 'Write to us for detailed inquiries',
      address: 'Address',
      addressDesc: 'Visit our office',
      hours: 'Business Hours',
      hoursDesc: 'Sunday - Thursday: 8:00 AM - 5:00 PM',
      social: 'Social Media',
      socialDesc: 'Follow us on social media'
    }
  },
  fr: {
    title: "Contactez-Nous",
    subtitle: "Nous sommes là pour vous aider et répondre à toutes vos questions",
    form: {
      name: 'Nom Complet',
      email: 'Adresse Email',
      phone: 'Numéro de Téléphone',
      subject: 'Sujet',
      message: 'Votre Message',
      send: 'Envoyer le Message'
    },
    info: {
      phone: 'Téléphone',
      phoneDesc: 'Appelez-nous directement pour une assistance immédiate',
      email: 'Email',
      emailDesc: 'Écrivez-nous pour des demandes détaillées',
      address: 'Adresse',
      addressDesc: 'Visitez notre bureau',
      hours: 'Heures d\'Ouverture',
      hoursDesc: 'Dimanche - Jeudi: 8h00 - 17h00',
      social: 'Réseaux Sociaux',
      socialDesc: 'Suivez-nous sur les réseaux sociaux'
    }
  },
  ar: {
    title: "تواصل معنا",
    subtitle: "نحن هنا للمساعدة والإجابة على أي أسئلة لديك",
    form: {
      name: 'الاسم الكامل',
      email: 'البريد الإلكتروني',
      phone: 'رقم الهاتف',
      subject: 'الموضوع',
      message: 'رسالتك',
      send: 'إرسال الرسالة'
    },
    info: {
      phone: 'الهاتف',
      phoneDesc: 'اتصل بنا مباشرة للمساعدة الفورية',
      email: 'البريد الإلكتروني',
      emailDesc: 'اكتب لنا للاستفسارات المفصلة',
      address: 'العنوان',
      addressDesc: 'قم بزيارة مكتبنا',
      hours: 'ساعات العمل',
      hoursDesc: 'الأحد - الخميس: 8:00 صباحاً - 5:00 مساءً',
      social: 'وسائل التواصل الاجتماعي',
      socialDesc: 'تابعنا على وسائل التواصل الاجتماعي'
    }
  }
};

const Contact: React.FC = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const [status, setStatus] = useState<'success' | 'error' | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setStatus(null);

    emailjs.send(
      'service_2z53z4c', // Replace with your EmailJS service ID
      'template_7w6fvik', // Replace with your EmailJS template ID
      {
        from_name: formData.name,
        from_email: formData.email,
        phone: formData.phone,
        subject: formData.subject,
        message: formData.message,
      },
      'Wy6IMNgXMAdsKDK_9' // Replace with your EmailJS user ID
    )
      .then(() => {
        setStatus('success');
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: ''
        });
      })
      .catch(() => {
        setStatus('error');
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className={`contact-section ${language === 'ar' ? 'rtl' : 'ltr'}`}>
      <SectionTitle 
        title={t.title}
        subtitle={t.subtitle}
      />

      <div className="contact-content">
        <motion.div 
          className="contact-form-container"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {status && (
            <div className={`status-message ${status}`}>
              {status === 'success' ? 'Message sent successfully!' : 'Failed to send message. Please try again.'}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <input 
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder={t.form.name}
                  required 
                />
              </div>
              <div className="form-group">
                <input 
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder={t.form.email}
                  required 
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <input 
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder={t.form.phone}
                  required 
                />
              </div>
              <div className="form-group">
                <input 
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder={t.form.subject}
                  required 
                />
              </div>
            </div>
            <div className="form-group">
              <textarea 
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder={t.form.message}
                rows={6}
                required 
              />
            </div>
            <motion.button
              type="submit"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {t.form.send}
            </motion.button>
          </form>
        </motion.div>

        <motion.div 
          className="contact-info"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="info-card">
            <FaPhoneAlt className="info-icon" />
            <div className="info-content">
              <h3>{t.info.phone}</h3>
              <p>{t.info.phoneDesc}</p>
              <a href="tel:+213555023075">+213 555 023 075</a>
            </div>
          </div>

          <div className="info-card">
            <FaEnvelope className="info-icon" />
            <div className="info-content">
              <h3>{t.info.email}</h3>
              <p>{t.info.emailDesc}</p>
              <a href="mailto:contact@gmi-dz.com">s.commercial@gmi-groupe.com</a>
            </div>
          </div>

          <div className="info-card">
            <FaMapMarkerAlt className="info-icon" />
            <div className="info-content">
              <h3>{t.info.address}</h3>
              <p>{t.info.addressDesc}</p>
              <address>Zone industrielle Oued-Sly, Chlef</address>
            </div>
          </div>

          <div className="info-card">
            <FaClock className="info-icon" />
            <div className="info-content">
              <h3>{t.info.hours}</h3>
              <p>{t.info.hoursDesc}</p>
            </div>
          </div>

          <div className="info-card social-card">
            <div className="info-content">
              <h3>{t.info.social}</h3>
              <p>{t.info.socialDesc}</p>
              <div className="social-links">
                <a href="https://wa.me/213555023075" target="_blank" rel="noopener noreferrer">
                  <FaWhatsapp />
                </a>
                <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                  <FaFacebook />
                </a>
                <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin />
                </a>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
