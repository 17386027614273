import React from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useLanguage } from '../context/LanguageContext';
import calqueImage from '../assets/images/purchase/calque1.png';
import './ProductDetails.css';

const translations = {
  en: {
    specifications: 'Technical Specifications',
    powerOutput: 'Power Output',
    engine: 'Engine',
    dimensions: 'Dimensions',
    weight: 'Weight',
    fuelConsumption: 'Fuel Consumption',
    backToProducts: 'Back to Products'
  },
  fr: {
    specifications: 'Spécifications Techniques',
    powerOutput: 'Puissance de Sortie',
    engine: 'Moteur',
    dimensions: 'Dimensions',
    weight: 'Poids',
    fuelConsumption: 'Consommation de Carburant',
    backToProducts: 'Retour aux Produits'
  },
  ar: {
    specifications: 'المواصفات الفنية',
    powerOutput: 'قدرة الإخراج',
    engine: 'المحرك',
    dimensions: 'الأبعاد',
    weight: 'الوزن',
    fuelConsumption: 'استهلاك الوقود',
    backToProducts: 'العودة إلى المنتجات'
  }
};

const ProductDetails: React.FC = () => {
  const { brand, model } = useParams();
  const { language } = useLanguage();
  const t = translations[language];

  // Product variants organized by engine model
  const productVariants = {
    '4M06GE': [
      {
        power: '20 kVA',
        specs: {
          engine: '4M06G20',
          dimensions: '2500 x 1000 x 1500 mm',
          weight: '- kg',
          fuelConsumption: '5.7 L/h @ 75% load'
        }
      },
      {
        power: '30 kVA',
        specs: {
          engine: '4M06G30',
          dimensions: '2500 x 1000 x 1500 mm',
          weight: '- kg',
          fuelConsumption: '6.2 L/h @ 75% load'
        }
      },
      {
        power: '40 kVA',
        specs: {
          engine: '4M06G40',
          dimensions: '2500 x 1000 x 1500 mm',
          weight: '- kg',
          fuelConsumption: '8.1 L/h @ 75% load'
        }
      },
      {
        power: '50 kVA',
        specs: {
          engine: '4M06G50',
          dimensions: '2500 x 1000 x 1500 mm',
          weight: '- kg',
          fuelConsumption: '9 L/h @ 75% load'
        }
      }
    ],
    '4M11GE': [
      {
        power: '60 kVA',
        specs: {
          engine: '4M11G60',
          dimensions: '2800 x 1100 x 1800 mm',
          weight: '- kg',
          fuelConsumption: '11.2 L/h @ 75% load'
        }
      },
      {
        power: '80 kVA',
        specs: {
          engine: '4M11G80',
          dimensions: '2800 x 1100 x 1800 mm',
          weight: '- kg',
          fuelConsumption: '13.3 L/h @ 75% load'
        }
      },
      {
        power: '100 kVA',
        specs: {
          engine: '4M11G100',
          dimensions: '2800 x 1100 x 1800 mm',
          weight: '- kg',
          fuelConsumption: '18.7 L/h @ 75% load'
        }
      }
    ],
    '6M11GE': [
      {
        power: '150 kVA',
        specs: {
          engine: '6M11G150',
          dimensions: '3200 x 1400 x 2100 mm',
          weight: '- kg',
          fuelConsumption: '23 L/h @ 75% load'
        }
      },
        {
          power: '200 kVA',
          specs: {
            engine: '6M11G200',
            dimensions: '3750 x 1400 x 2100 mm',
            weight: '- kg',
            fuelConsumption: '31.6 L/h @ 75% load'
          }
        }
      ],
      '6M16GE': [
        {
          power: '250 kVA',
          specs: {
            engine: '6M16G250',
            dimensions: '3750 x 1400 x 2100 mm',
            weight: '- kg',
            fuelConsumption: '42 L/h @ 75% load'
          }
        },
        {
          power: '300 kVA',
          specs: {
            engine: '6M16G300',
            dimensions: '3750 x 1400 x 2100 mm',
            weight: '- kg',
            fuelConsumption: '53 L/h @ 75% load'
          }
        }
      ],
      '6M21GE': [
        {
            power: '400 kVA',
            specs: {
              engine: '6M21G400',
              dimensions: '4850 x 1600 x 2450 mm',
              weight: '- kg',
              fuelConsumption: '63 L/h @ 75% load'
            }
          },
        {
          power: '500 kVA',
          specs: {
            engine: '6M21G500',
            dimensions: '5000 x 1600 x 2500 mm',
            weight: '- kg',
            fuelConsumption: '69 L/h @ 75% load'
          }
        }
      ],
      '6M33GE': [
        {
          power: '600 kVA',
          specs: {
            engine: '6M33G600',
            dimensions: '5000 x 1600 x 2500 mm',
            weight: '- kg',
            fuelConsumption: '79 L/h @ 75% load'
          }
        }
        ,
        {
          power: '700 kVA',
          specs: {
            engine: '6M33G700',
            dimensions: '5000 x 1600 x 2500 mm',
            weight: '- kg',
            fuelConsumption: '92 L/h @ 75% load'
          }
        },
        {
            power: '800 kVA',
            specs: {
              engine: '6M33G800',
              dimensions: '5000 x 2400 x 2850 mm',
              weight: '- kg',
              fuelConsumption: '112 L/h @ 75% load'
            }
          }
      ],
      '12M26GE': [
        {
            power: '1000 kVA',
            specs: {
              engine: '12M26G1000',
              dimensions: '5000 x 2400 x 3500 mm',
              weight: '- kg',
              fuelConsumption: '154 L/h @ 75% load'
            }
          }
      ],
      '12M33GE': [
        {
            power: '1250 kVA',
            specs: {
              engine: '12M33G1250',
              dimensions: '7000 x 2400 x 3600 mm',
              weight: '- kg',
              fuelConsumption: '191 L/h @ 75% load'
            }
          },
          {
            power: '1600 kVA',
            specs: {
              engine: '12M33G1600',
              dimensions: '8000 x 2500 x 3800 mm',
              weight: '- kg',
              fuelConsumption: '241 L/h @ 75% load'
            }
          }
      ],
      '16M33GE': [
        {
            power: '2000 kVA',
            specs: {
              engine: '16M33G2000',
              dimensions: '8000 x 2500 x 3800 mm',
              weight: '- kg',
              fuelConsumption: '262 L/h @ 75% load'
            }
          }
      ],
      'TAD5xxGE': [
        {
            power: '85 kVA',
            specs: {
              engine: 'TAD531',
              dimensions: '3000 x 1000 x 1500 mm',
              weight: '- kg',
              fuelConsumption: '12.3 L/h @ 75% load'
            }
          },
          {
            power: '100 kVA',
            specs: {
              engine: 'TAD531',
              dimensions: '3000 x 1000 x 1500 mm',
              weight: '- kg',
              fuelConsumption: '18.5 L/h @ 75% load'
            }
          }
      ],
      'TAD7xxGE': [
        {
            power: '150 kVA',
            specs: {
              engine: 'TAD731',
              dimensions: '3500 x 1100 x 1500 mm',
              weight: '- kg',
              fuelConsumption: '24.5 L/h @ 75% load'
            }
          },
          {
            power: '200 kVA',
            specs: {
              engine: 'TAD731',
              dimensions: '3750 x 1400 x 2100 mm',
              weight: '- kg',
              fuelConsumption: '34 L/h @ 75% load'
            }
          }
      ],
      'TAD8xxGE': [
        {
            power: '250 kVA',
            specs: {
              engine: 'TAD841',
              dimensions: '3750 x 1400 x 2100 mm',
              weight: '- kg',
              fuelConsumption: '36.3 L/h @ 75% load'
            }
          },
          {
            power: '300 kVA',
            specs: {
              engine: 'TAD841',
              dimensions: '4850 x 1600 x 2450 mm',
              weight: '- kg',
              fuelConsumption: '45 L/h @ 75% load'
            }
          }
      ],
      'TAD13xxGE': [
        {
            power: '400 kVA',
            specs: {
              engine: 'TAD1343',
              dimensions: '4850 x 1600 x 2450 mm',
              weight: '- kg',
              fuelConsumption: '59 L/h @ 75% load'
            }
          },
          {
            power: '450 kVA',
            specs: {
              engine: 'TAD1344',
              dimensions: '4850 x 1600 x 2450 mm',
              weight: '- kg',
              fuelConsumption: '59 L/h @ 75% load'
            }
          }
      ],
      'TAD16xxGE': [
        {
            power: '500 kVA',
            specs: {
              engine: 'TAD1641GE',
              dimensions: '4850 x 1600 x 2450 mm',
              weight: '- kg',
              fuelConsumption: '77 L/h @ 75% load'
            }
          },
          {
            power: '600 kVA',
            specs: {
              engine: 'TAD1642GE',
              dimensions: '4850 x 1600 x 2450 mm',
              weight: '- kg',
              fuelConsumption: '83 L/h @ 75% load'
            }
          },
          {
            power: '700 kVA',
            specs: {
              engine: 'TAD1644GE',
              dimensions: '4850 x 1600 x 2450 mm',
              weight: '- kg',
              fuelConsumption: '89 L/h @ 75% load'
            }
          }
      ],
      'TAD17xxGE':[
        {
            power: '800 kVA',
            specs: {
              engine: 'TAD1743GE',
              dimensions: '4850 x 1600 x 2450 mm',
              weight: '- kg',
              fuelConsumption: '113.4 L/h @ 75% load'
            }
          }
      ]
  };

  // Get the correct variants based on the model
  const variants = productVariants[model as keyof typeof productVariants] || [];

  return (
    <div className={`product-details-page ${language === 'ar' ? 'rtl' : 'ltr'}`}>
      <div className="product-details-header">
        <h1>{brand?.toUpperCase()} {model}</h1>
        <p className="model-description">
          {model === '4M11GE' ? 
            language === 'fr' ? 
              'Gamme de puissance moyenne avec une excellente efficacité énergétique' :
            language === 'ar' ? 
              'نطاق القدرة المتوسطة مع كفاءة ممتازة في استهلاك الوقود' :
              'Medium power range with excellent fuel efficiency' :
            language === 'fr' ?
              'Gamme de puissance compacte idéale pour les applications légères' :
            language === 'ar' ?
              'نطاق قدرة مدمج مثالي للتطبيقات الخفيفة' :
              'Compact power range ideal for light applications'
          }
        </p>
      </div>
      
      <div className="power-variants-grid">
        {variants.map((variant, index) => (
          <motion.div 
            key={variant.power}
            className="variant-card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <div className="variant-header">
              <img 
                src={calqueImage} 
                alt={`${variant.power} Generator`} 
                className="variant-image"
              />
              <h2>{variant.power}</h2>
            </div>
            <div className="specs-grid">
              <div className="spec-item">
                <h3>{t.engine}</h3>
                <p>{variant.specs.engine}</p>
              </div>
              <div className="spec-item">
                <h3>{t.dimensions}</h3>
                <p>{variant.specs.dimensions}</p>
              </div>
              <div className="spec-item">
                <h3>{t.weight}</h3>
                <p>{variant.specs.weight}</p>
              </div>
              <div className="spec-item">
                <h3>{t.fuelConsumption}</h3>
                <p>{variant.specs.fuelConsumption}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ProductDetails; 