import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLanguage } from '../../context/LanguageContext';
import SectionTitle from '../SectionTitle';

// Import partner logos
import hamoudLogo from '../../assets/images/partners/hamoud.png';
import cocacolaLogo from '../../assets/images/partners/cocacola.png';
import chialiLogo from '../../assets/images/partners/chiali.png';
import djezzyLogo from '../../assets/images/partners/djezzy.png';
import ooredooLogo from '../../assets/images/partners/ooredoo.png';
import huaweiLogo from '../../assets/images/partners/huawei.png';

const SliderContainer = styled.div`
  padding: 4rem 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 100%;
    top: 0;
    z-index: 2;
    pointer-events: none;
  }

  &::before {
    left: 0;
    background: linear-gradient(90deg, #f8f9fa 0%, transparent 100%);
  }

  &::after {
    right: 0;
    background: linear-gradient(-90deg, #f8f9fa 0%, transparent 100%);
  }
`;

const StyledSlider = styled(Slider)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-slide {
    padding: 0 1.5rem;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const PartnerLogo = styled(motion.img)`
  width: 100%;
  max-width: 180px;
  height: auto;
  opacity: 0.9;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const PartnerCard = styled(motion.div)`
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }
`;

interface Partner {
  id: number;
  logo: string;
  name: string;
}

const PartnersSlider: React.FC = () => {
  const { language } = useLanguage();
  
  const translations = {
    en: {
      title: "Who Trusts Us",
      subtitle: "Trusted by leading companies across Algeria"
    },
    fr: {
      title: "Qui nous ont fait confiance",
      subtitle: "Fait confiance par les principales entreprises à travers l'Algérie"
    },
    ar: {
      title: "من يثق بنا",
      subtitle: "موثوق به من قبل الشركات الرائدة في جميع أنحاء الجزائر"
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  const partners: Partner[] = [
    { id: 1, logo: hamoudLogo, name: 'Hamoud Boualem' },
    { id: 2, logo: cocacolaLogo, name: 'Coca Cola' },
    { id: 3, logo: chialiLogo, name: 'Chiali' },
    { id: 4, logo: djezzyLogo, name: 'Djezzy' },
    { id: 5, logo: ooredooLogo, name: 'Ooredoo' },
    { id: 6, logo: huaweiLogo, name: 'Huawei' },
  ];

  return (
    <SliderContainer>
      <SectionTitle 
        title={translations[language].title}
        subtitle={translations[language].subtitle}
      />
      <StyledSlider {...settings}>
        {partners.map((partner, index) => (
          <PartnerCard
            key={partner.id}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <PartnerLogo
              src={partner.logo}
              alt={partner.name}
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 300 }}
            />
          </PartnerCard>
        ))}
      </StyledSlider>
    </SliderContainer>
  );
};

export default PartnersSlider;
