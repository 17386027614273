import React from 'react';
import styled from 'styled-components';
import { useLanguage } from '../../context/LanguageContext';
import SectionTitle from '../SectionTitle';

import datacenter from '../../assets/images/projects/datacenter.jpg';
import hopital from '../../assets/images/projects/hopital.jpg';
import hotel from '../../assets/images/projects/hotel.jpg';
import site from '../../assets/images/projects/site.jpg';

const ProjectsContainer = styled.div`
  padding: 5rem calc((100vw - 1200px) / 2);
  margin-top: 0.5rem;
`;

const TitleWrapper = styled.div`
  margin-top: 0.5rem;
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const ProjectCard = styled.div`
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }
`;

const ProjectImage = styled.div<{ imageUrl: string }>`
  width: 100%;
  height: 250px;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-position: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);
  }
`;

const ProjectInfo = styled.div`
  padding: 20px;
`;

const ProjectTitle = styled.h3`
  color: #00813C;
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const ProjectDescription = styled.p`
  color: #4a4a4a;
  line-height: 1.6;
  margin-bottom: 15px;
`;

const ProjectDetails = styled.div`
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 0.9rem;
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 15px;
`;

const Projects: React.FC = () => {
  const { language } = useLanguage();

  const projects = {
    title: {
      en: 'Our Major Projects',
      fr: 'Nos Grands Projets',
      ar: 'مشاريعنا الكبرى'
    },
    items: [
      {
        image: datacenter, 
        title: {
          en: 'Modern Data Center Backup Power',
          fr: 'Alimentation de Secours pour Centre de Données',
          ar: 'طاقة احتياطية لمركز البيانات الحديث'
        },
        description: {
          en: 'Installation of 4x 2000 kVA generator sets for a tier-3 data center, ensuring 99.999% uptime with N+1 redundancy.',
          fr: 'Installation de 4 groupes électrogènes de 2000 kVA pour un centre de données tier-3, assurant une disponibilité de 99,999% avec une redondance N+1.',
          ar: 'تركيب 4 مولدات كهربائية بقدرة 2000 كيلو فولت أمبير لمركز بيانات من المستوى الثالث، مما يضمن توافر بنسبة 99.999٪ مع نظام احتياطي.'
        },
        capacity: '8000 kVA'
      },
      {
        image: hopital,
        title: {
          en: 'Hospital Emergency Power',
          fr: 'Alimentation d\'Urgence pour Un Hôpital',
          ar: 'طاقة الطوارئ للمستشفى '
        },
        description: {
          en: 'Critical power supply system with 3x 1000 kVA generators for a major hospital, including automatic transfer switches and synchronized operation.',
          fr: 'Système d\'alimentation critique avec 3 générateurs de 1000 kVA pour un grand hôpital, incluant des commutateurs de transfert automatique et un fonctionnement synchronisé.',
          ar: 'نظام إمداد طاقة حيوي مع 3 مولدات بقدرة 1000 كيلو فولت أمبير لمستشفى جامعي كبير، يشمل مفاتيح نقل أوتوماتيكية وتشغيل متزامن.'
        },
        capacity: '3000 kVA'
      },
      {
        image: hotel,
        title: {
          en: 'Luxury Hotel Power Solution',
          fr: 'Solution Énergétique pour Hôtel de Luxe',
          ar: 'حل الطاقة للفندق الفاخر'
        },
        description: {
          en: 'Complete power solution for a 5-star hotel with 2x 1500 kVA generators, featuring ultra-silent operation and remote monitoring system.',
          fr: 'Solution énergétique complète pour un hôtel 5 étoiles avec 2 générateurs de 1500 kVA, fonctionnement ultra-silencieux et système de surveillance à distance.',
          ar: 'حل طاقة متكامل لفندق 5 نجوم مع مولدين بقدرة 1500 كيلو فولت أمبير، يتميز بتشغيل فائق الهدوء ونظام مراقبة عن بعد.'
        },
        capacity: '3000 kVA'
      },
      {
        image: site,
        title: {
          en: 'Industrial Manufacturing Facility',
          fr: 'Site de Production Industrielle',
          ar: 'منشأة التصنيع الصناعي'
        },
        description: {
          en: 'Power system for a large manufacturing facility with 2x 2000 kVA generators, including load management and peak shaving capabilities.',
          fr: 'Système électrique pour une grande usine de fabrication avec 2 générateurs de 2000 kVA, incluant la gestion de charge et les capacités d\'écrêtement des pointes.',
          ar: 'نظام طاقة لمنشأة تصنيع كبيرة مع مولدين بقدرة 2000 كيلو فولت أمبير، يشمل إدارة الأحمال وقدرات تخفيف الذروة.'
        },
        capacity: '4000 kVA'
      }
    ]
  };

  const translations = {
    en: {
      title: "Our Projects",
      subtitle: "Successful implementations across various sectors"
    },
    fr: {
      title: "Nos Projets",
      subtitle: "Des réalisations réussies dans différents secteurs"
    },
    ar: {
      title: "مشاريعنا",
      subtitle: "تنفيذات ناجحة في مختلف القطاعات"
    }
  };

  return (
    <section className={`projects-section ${language === 'ar' ? 'rtl' : ''}`}>
      <ProjectsContainer>
        <TitleWrapper>
          <SectionTitle 
            title={translations[language].title}
            subtitle={translations[language].subtitle}
          />
        </TitleWrapper>
        <ProjectsGrid>
          {projects.items.map((project, index) => (
            <ProjectCard key={index}>
              <ProjectImage imageUrl={project.image} />
              <ProjectInfo>
                <ProjectTitle>{project.title[language]}</ProjectTitle>
                <ProjectDescription>{project.description[language]}</ProjectDescription>
                <ProjectDetails>
                  <span>
                    {language === 'fr' ? 'Capacité Totale' : 
                     language === 'ar' ? 'القدرة الإجمالية' : 
                     'Total Capacity'}: {project.capacity}
                  </span>
                </ProjectDetails>
              </ProjectInfo>
            </ProjectCard>
          ))}
        </ProjectsGrid>
      </ProjectsContainer>
    </section>
  );
};

export default Projects;
