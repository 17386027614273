import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import logo from '../../assets/images/logo/gmi_logo.jpg';

const Nav = styled.nav<{ scrolled: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem calc((100vw - 1300px) / 2);
  z-index: 999;
  transition: all 0.3s ease-in-out;
  background: ${({ scrolled }) => scrolled ? 'rgba(255, 255, 255, 0.95)' : 'transparent'};
  backdrop-filter: ${({ scrolled }) => scrolled ? 'blur(10px)' : 'none'};
  box-shadow: ${({ scrolled }) => scrolled ? '0 2px 10px rgba(0, 0, 0, 0.1)' : 'none'};

  @media screen and (max-width: 1300px) {
    padding: 0.5rem 1rem;
  }
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
`;

const LogoImage = styled.img`
  height: 60px;
  width: auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)<{ $isActive?: boolean }>`
  color: ${({ $isActive }) => $isActive ? '#00813C' : '#333'};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease;

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: ${({ $isActive }) => $isActive ? '30px' : '0'};
    height: 2px;
    background-color: #00813C;
    transition: all 0.3s ease;
  }

  &:hover {
    color: #00813C;
    
    &:after {
      width: 30px;
    }
  }
`;

const MobileIcon = styled.div`
  display: none;
  color: #333;
  cursor: pointer;
  padding: 0 1rem;

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
  }
`;

const MobileMenu = styled.div<{ isOpen: boolean }>`
  display: none;
  
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 80px;
    left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const MobileLink = styled(Link)<{ $isActive?: boolean }>`
  color: ${({ $isActive }) => $isActive ? '#00813C' : '#333'};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 1rem;
  width: 100%;
  transition: all 0.3s ease;
  font-weight: 500;

  &:hover {
    background: rgba(0, 129, 60, 0.1);
    color: #00813C;
  }
`;

const LanguageSelect = styled.select`
  padding: 0.5rem;
  margin-left: 1rem;
  border: 1px solid #00813C;
  border-radius: 4px;
  background: transparent;
  color: #333;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  outline: none;
  
  &:hover {
    background: rgba(0, 129, 60, 0.1);
  }

  &:focus {
    border-color: #00813C;
    box-shadow: 0 0 0 2px rgba(0, 129, 60, 0.1);
  }

  @media screen and (max-width: 768px) {
    margin: 1rem;
    width: calc(100% - 2rem);
  }
`;

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { language, setLanguage } = useLanguage();
  const location = useLocation();

  const translations = {
    home: {
      en: 'Home',
      fr: 'Accueil',
      ar: 'الرئيسية'
    },
    about: {
      en: 'About',
      fr: 'À Propos',
      ar: 'حول'
    },
    services: {
      en: 'Services',
      fr: 'Services',
      ar: 'خدمات'
    },
    products: {
      en: 'Products',
      fr: 'Produits',
      ar: 'منتجات'
    },
    projects: {
      en: 'Projects',
      fr: 'Projets',
      ar: 'مشاريع'
    },
    contact: {
      en: 'Contact',
      fr: 'Contact',
      ar: 'اتصل بنا'
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLang = event.target.value as 'en' | 'fr' | 'ar';
    setLanguage(newLang);
    document.documentElement.dir = newLang === 'ar' ? 'rtl' : 'ltr';
  };

  return (
    <Nav scrolled={scrolled}>
      <LogoLink to="/">
        <LogoImage src={logo} alt="GMI ALGERIE Logo" />
      </LogoLink>
      <MobileIcon onClick={toggleMenu}>
        {isOpen ? '✕' : '☰'}
      </MobileIcon>
      <NavMenu>
        <NavLink to="/" $isActive={location.pathname === '/'}>
          {translations.home[language]}
        </NavLink>
        <NavLink to="/about" $isActive={location.pathname === '/about'}>
          {translations.about[language]}
        </NavLink>
        <NavLink to="/services" $isActive={location.pathname === '/services'}>
          {translations.services[language]}
        </NavLink>
        <NavLink to="/products" $isActive={location.pathname === '/products'}>
          {translations.products[language]}
        </NavLink>
        <NavLink to="/projects" $isActive={location.pathname === '/projects'}>
          {translations.projects[language]}
        </NavLink>
        <NavLink to="/contact" $isActive={location.pathname === '/contact'}>
          {translations.contact[language]}
        </NavLink>
        <LanguageSelect value={language} onChange={handleLanguageChange}>
          <option value="fr">Français</option>
          <option value="en">English</option>
          <option value="ar">العربية</option>
        </LanguageSelect>
      </NavMenu>
      <MobileMenu isOpen={isOpen}>
        <MobileLink to="/" $isActive={location.pathname === '/'} onClick={closeMenu}>
          {translations.home[language]}
        </MobileLink>
        <MobileLink to="/about" $isActive={location.pathname === '/about'} onClick={closeMenu}>
          {translations.about[language]}
        </MobileLink>
        <MobileLink to="/services" $isActive={location.pathname === '/services'} onClick={closeMenu}>
          {translations.services[language]}
        </MobileLink>
        <MobileLink to="/products" $isActive={location.pathname === '/products'} onClick={closeMenu}>
          {translations.products[language]}
        </MobileLink>
        <MobileLink to="/projects" $isActive={location.pathname === '/projects'} onClick={closeMenu}>
          {translations.projects[language]}
        </MobileLink>
        <MobileLink to="/contact" $isActive={location.pathname === '/contact'} onClick={closeMenu}>
          {translations.contact[language]}
        </MobileLink>
        <LanguageSelect value={language} onChange={handleLanguageChange}>
          <option value="fr">Français</option>
          <option value="en">English</option>
          <option value="ar">العربية</option>
        </LanguageSelect>
      </MobileMenu>
    </Nav>
  );
};

export default Navbar;
