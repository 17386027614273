import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Slider from '../Slider/Slider';
import { useLanguage } from '../../context/LanguageContext';

const HeroSection = styled.section`
  min-height: 100vh;
  height: auto;
  position: relative;
  overflow: hidden;
  padding-top: 80px;
  background-image: url('/hero-bg.jpg');
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    min-height: calc(100vh - 80px);
  }
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 3;
  color: #fff;
  width: 90%;
  max-width: 1200px;
  padding: 2rem;

  h1 {
    font-size: clamp(2rem, 5vw, 4rem);
    margin-bottom: 1rem;
    font-weight: 700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  p {
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    margin-bottom: 2rem;
    line-height: 1.6;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
`;

const HeroButton = styled(Link)`
  background: #057707;
  color: var(--primary-white);
  padding: 1rem 2rem;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 700;
  transition: var(--transition);
  text-decoration: none;
  display: inline-block;

  &:hover {
    background: #046106;
    transform: translateY(-2px);
    color: var(--primary-white);
  }
`;

const ScrollIndicator = styled(motion.div)`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  .mouse {
    width: 25px;
    height: 45px;
    border: 2px solid var(--primary-white);
    border-radius: 15px;
    position: relative;
  }

  .wheel {
    width: 3px;
    height: 8px;
    background: var(--primary-white);
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3px;
    animation: scroll 1.5s infinite;
  }

  @keyframes scroll {
    0% {
      transform: translate(-50%, 0);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, 15px);
      opacity: 0;
    }
  }
`;

const Hero: React.FC = () => {
  const { t } = useLanguage();
  
  return (
    <HeroSection>
      <Slider />
      <HeroContent>
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          {t('hero.title')}
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          {t('hero.subtitle')}
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <HeroButton to="/about">{t('hero.cta')}</HeroButton>
        </motion.div>
      </HeroContent>
      <ScrollIndicator
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.2 }}
      >
        <div className="mouse">
          <div className="wheel" />
        </div>
      </ScrollIndicator>
    </HeroSection>
  );
};

export default Hero;
