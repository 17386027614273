import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import baudouinLogo from '../../assets/images/brands/baudouin.png';
import volvoLogo from '../../assets/images/brands/volvo.png';
import './ProductCard.css';

interface ProductCardProps {
  product: {
    id: string;
    image: string;
    model: string;
    power: string;
    brand: string;
  };
  translations: {
    powerRange: string;
    kVA: string;
    learnMore: string;
  };
}

const ProductCard: React.FC<ProductCardProps> = ({ product, translations }) => {
  const navigate = useNavigate();
  const brandLogo = product.brand.toLowerCase() === 'baudouin' ? baudouinLogo : volvoLogo;

  const handleLearnMore = () => {
    navigate(`/groupe-electrogene/${product.brand.toLowerCase()}/${product.power}`);
  };

  return (
    <motion.div 
      className="product-card"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.03 }}
    >
      <div className="product-image">
        <img src={product.image} alt={product.model} className="main-image" />
      </div>
      <div className="product-info">
        {brandLogo && (
          <div className="brand-logo-container">
            <img src={brandLogo} alt={product.brand} className="brand-logo" />
          </div>
        )}
        <h3 className="power-rating">{product.power}</h3>
        <button className="learn-more-btn" onClick={handleLearnMore}>
          {translations.learnMore}
        </button>
      </div>
    </motion.div>
  );
};

export default ProductCard; 