import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';
import { useLanguage } from '../../context/LanguageContext';
import './PurchaseButton.css';

const purchaseText = {
  en: 'Purchase Now',
  fr: 'Acheter Maintenant',
  ar: 'اشتري الآن'
};

const PurchaseButton: React.FC = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();

  return (
    <button 
      className={`purchase-button ${language === 'ar' ? 'rtl' : 'ltr'}`}
      onClick={() => navigate('/groupe-electrogene')}
    >
      <FaShoppingCart size={20} style={{ color: 'white' }} />
      {purchaseText[language]}
    </button>
  );
};

export default PurchaseButton; 