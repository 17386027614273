import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background: linear-gradient(135deg, #00813C 0%, #005025 100%);
  color: white;
  padding: 2rem 0 0 0;
  position: relative;
  overflow: hidden;
`;

const FooterContent = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const FooterSection = styled.div`
  h3 {
    color: white;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.3rem;
      width: 50px;
      height: 2px;
      background: #E31E24;
      
      @media (max-width: 768px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

const LocationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0.8rem;
  margin-top: 0.8rem;
`;

const LocationCard = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 0.8rem;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    background: rgba(255, 255, 255, 0.15);
  }

  h4 {
    color: #E31E24;
    margin-bottom: 0.3rem;
    font-size: 0.9rem;
  }

  p {
    font-size: 0.8rem;
    line-height: 1.3;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  font-size: 0.9rem;
  
  @media (max-width: 768px) {
    justify-content: center;
  }

  svg {
    margin-right: 0.5rem;
    color: #E31E24;
    font-size: 0.9rem;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-top: 1rem;
  
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SocialIcon = styled.a`
  background: rgba(255, 255, 255, 0.1);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  color: white;
  text-decoration: none;
  font-size: 0.9rem;

  &:hover {
    background: #E31E24;
    transform: translateY(-2px);
  }
`;

const QuickLinks = styled.div`
  display: grid;
  gap: 0.4rem;

  a {
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    font-size: 0.9rem;

    &:hover {
      color: #E31E24;
      transform: translateX(3px);
    }

    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;

const MapSection = styled.div`
  position: relative;
  height: 200px;
  background: url('https://upload.wikimedia.org/wikipedia/commons/7/7f/Algeria_map-fr.svg') center/contain no-repeat;
  filter: brightness(0.8) invert(1);
  opacity: 0.2;
  margin: 1.5rem 0;
`;

const LocationMarker = styled.div<{ top: string; left: string }>`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  width: 8px;
  height: 8px;
  background: #E31E24;
  border-radius: 50%;
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(227, 30, 36, 0.7);
    }
    70% {
      transform: scale(1.2);
      box-shadow: 0 0 0 6px rgba(227, 30, 36, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(227, 30, 36, 0);
    }
  }
`;

const Copyright = styled.div`
  text-align: center;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  font-size: 0.9rem;
`;

const Footer: React.FC = () => {
  const { language } = useLanguage();

  const translations = {
    contact: {
      en: 'Contact Us',
      fr: 'Contactez-nous',
      ar: 'اتصل بنا'
    },
    quickLinks: {
      en: 'Quick Links',
      fr: 'Liens Rapides',
      ar: 'روابط سريعة'
    },
    locations: {
      en: 'Our Locations',
      fr: 'Nos Emplacements',
      ar: 'مواقعنا'
    },
    copyright: {
      en: ' 2025 GMI ALGERIE. All rights reserved.',
      fr: ' 2025 GMI ALGERIE. Tous droits réservés.',
      ar: ' 2025 جي إم آي الجزائر. جميع الحقوق محفوظة.'
    }
  };

  const locations = [
    {
      name: 'Chlef',
      address: 'Zone Industrielle Oued Sly, Chlef',
      coordinates: { top: '35%', left: '30%' }
    },
    {
      name: 'Bordj El Kiffan',
      address: 'Bordj El Kiffan, Alger',
      coordinates: { top: '30%', left: '40%' }
    },
    {
      name: 'Oued Smar',
      address: 'Zone Industrielle Oued Smar, Alger',
      coordinates: { top: '32%', left: '42%' }
    },
    {
      name: 'Oran',
      address: 'Résidence Mirabelle, Oran',
      coordinates: { top: '35%', left: '20%' }
    }
  ];

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>{translations.contact[language]}</h3>
          <ContactInfo>
            <FaPhone />
            <span>+213 (5) 55 02 30 75</span>
          </ContactInfo>
          <ContactInfo>
            <FaEnvelope />
            <span>s.commercial@gmi-groupe.com</span>
          </ContactInfo> 
          <SocialLinks>
            <SocialIcon href="https://facebook.com" target="_blank">
              <FaFacebookF />
            </SocialIcon>
            <SocialIcon href="https://linkedin.com" target="_blank">
              <FaLinkedinIn />
            </SocialIcon>
            <SocialIcon href="https://instagram.com" target="_blank">
              <FaInstagram />
            </SocialIcon>
          </SocialLinks>
        </FooterSection>

        <FooterSection>
          <h3>{translations.quickLinks[language]}</h3>
          <QuickLinks>
            <Link to="/about">À Propos</Link>
            <Link to="/services">Services</Link>
            <Link to="/products">Produits</Link>
            <Link to="/projects">Projets</Link>
            <Link to="/contact">Contact</Link>
          </QuickLinks>
        </FooterSection>

        <FooterSection>
          <h3>{translations.locations[language]}</h3>
          <LocationGrid>
            {locations.map((location, index) => (
              <LocationCard key={index}>
                <h4>{location.name}</h4>
                <p>{location.address}</p>
              </LocationCard>
            ))}
          </LocationGrid>
        </FooterSection>
      </FooterContent>

      <MapSection>
        {locations.map((location, index) => (
          <LocationMarker
            key={index}
            top={location.coordinates.top}
            left={location.coordinates.left}
          />
        ))}
      </MapSection>

      <Copyright>
        {translations.copyright[language]}
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
