import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaTruck, FaClock, FaShieldAlt, FaHeadset, FaTools } from 'react-icons/fa';
import { useLanguage } from '../../context/LanguageContext';
import './RequestQuoteForm.css';
import emailjs from '@emailjs/browser';

interface RequestQuoteFormProps {
  translations: {
    requestQuote: string;
  };
}

type GeneratorBrand = 'baudouin' | 'volvoPenta' | '';

interface GeneratorModels {
  baudouin: number[];
  volvoPenta: number[];
}

interface FormData {
  name: string;
  email: string;
  phone: string;
  brand: string;
  model: string;
  message: string;
}

const features = {
  en: [
    {
      icon: <FaTools />,
      title: 'After-Sales Service',
      description: 'Guaranteed professional maintenance and support'
    },
    {
      icon: <FaClock />,
      title: '24/7 Support',
      description: 'Round-the-clock technical assistance'
    },
    {
      icon: <FaShieldAlt />,
      title: 'Warranty',
      description: 'Extended warranty on all generators'
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Consultation',
      description: 'Professional guidance for your needs'
    }
  ],
  fr: [
    {
      icon: <FaTools />,
      title: 'Service Après-Vente',
      description: 'Maintenance et support professionnel garantis'
    },
    {
      icon: <FaClock />,
      title: 'Support 24/7',
      description: 'Assistance technique permanente'
    },
    {
      icon: <FaShieldAlt />,
      title: 'Garantie',
      description: 'Garantie prolongée sur tous les générateurs'
    },
    {
      icon: <FaHeadset />,
      title: 'Consultation Expert',
      description: 'Conseils professionnels adaptés à vos besoins'
    }
  ],
  ar: [
    {
      icon: <FaTools />,
      title: 'خدمة ما بعد البيع',
      description: 'صيانة ودعم احترافي مضمون'
    },
    {
      icon: <FaClock />,
      title: 'دعم على مدار الساعة',
      description: 'مساعدة تقنية على مدار الساعة'
    },
    {
      icon: <FaShieldAlt />,
      title: 'ضمان',
      description: 'ضمان ممتد على جميع المولدات'
    },
    {
      icon: <FaHeadset />,
      title: 'استشارة خبير',
      description: 'توجيه مهني لاحتياجاتك'
    }
  ]
};

const stats = {
  en: [
    { number: '25+', text: 'Years Experience' },
    { number: '10000+', text: 'Installations' },
    { number: '100%', text: 'Client Satisfaction' }
  ],
  fr: [
    { number: '25+', text: "Années d'Expérience" },
    { number: '10000+', text: 'Installations' },
    { number: '100%', text: 'Satisfaction Client' }
  ],
  ar: [
    { number: '+25', text: 'سنوات من الخبرة' },
    { number: '+10000', text: 'تركيب' },
    { number: '%100', text: 'رضا العملاء' }
  ]
};

const titles = {
  en: {
    why: 'Why Choose Our Generators?',
    subtitle: 'Premium quality generators backed by exceptional service and support'
  },
  fr: {
    why: 'Pourquoi Choisir Nos Générateurs?',
    subtitle: 'Générateurs de qualité supérieure soutenus par un service et un support exceptionnels'
  },
  ar: {
    why: 'لماذا تختار مولداتنا؟',
    subtitle: 'مولدات عالية الجودة مدعومة بخدمة ودعم استثنائيين'
  }
};

const generatorModels: GeneratorModels = {
  baudouin: [20, 30, 40, 50, 60, 80, 100, 150, 200, 250, 300, 400, 500, 600, 700, 800, 1000, 1250, 1600, 1900],
  volvoPenta: [85, 100, 150, 200, 300, 400, 500, 600, 700, 800]
};

const modelLabels = {
  en: {
    selectBrand: 'Select Brand',
    selectModel: 'Select Power (kVA)',
    baudouin: 'Baudouin',
    volvoPenta: 'Volvo Penta'
  },
  fr: {
    selectBrand: 'Sélectionner la Marque',
    selectModel: 'Sélectionner la Puissance (kVA)',
    baudouin: 'Baudouin',
    volvoPenta: 'Volvo Penta'
  },
  ar: {
    selectBrand: 'اختر العلامة التجارية',
    selectModel: 'اختر القدرة (ك.ف.أ)',
    baudouin: 'بودوان',
    volvoPenta: 'فولفو بنتا'
  }
};

const RequestQuoteForm: React.FC<RequestQuoteFormProps> = ({ translations }) => {
  const { language } = useLanguage();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    brand: '',
    model: '',
    message: ''
  });

  const [selectedBrand, setSelectedBrand] = useState<GeneratorBrand>('');
  const [status, setStatus] = useState<string | null>(null);

  const handleBrandChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const brand = e.target.value as GeneratorBrand;
    setSelectedBrand(brand);
    setFormData(prev => ({
      ...prev,
      brand,
      model: ''
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setStatus(null);

    emailjs.send(
      'service_2z53z4c',
      'template_7w6fvik',
      {
        from_name: formData.name,
        from_email: formData.email,
        phone: formData.phone,
        brand: formData.brand,
        model: formData.model,
        message: formData.message,
      },
      'Wy6IMNgXMAdsKDK_9'
    )
      .then(() => {
        setStatus('success');
        setFormData({
          name: '',
          email: '',
          phone: '',
          brand: '',
          model: '',
          message: ''
        });
      })
      .catch(() => {
        setStatus('error');
      });
  };

  return (
    <div className="quote-section">
      <div className="quote-content">
        <motion.div 
          className="quote-features"
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2>{titles[language].why}</h2>
          <p className="feature-subtitle">
            {titles[language].subtitle}
          </p>
          
          <div className="features-grid">
            {features[language].map((feature, index) => (
              <motion.div 
                key={index}
                className="feature-card"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="feature-icon">
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </motion.div>
            ))}
          </div>

          <div className="quote-stats">
            {stats[language].map((stat, index) => (
              <div key={index} className="stat-item">
                <h3>{stat.number}</h3>
                <p>{stat.text}</p>
              </div>
            ))}
          </div>
        </motion.div>

        <motion.div 
          className="quote-form-container"
          initial={{ opacity: 0, x: 20 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2>{translations.requestQuote}</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Full Name"
                value={formData.name}
                onChange={(e) => setFormData({...formData, name: e.target.value})}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={(e) => setFormData({...formData, phone: e.target.value})}
                required
              />
            </div>
            <div className="form-group">
              <select
                value={formData.brand}
                onChange={handleBrandChange}
                required
                className="form-select"
              >
                <option value="">{modelLabels[language].selectBrand}</option>
                <option value="baudouin">{modelLabels[language].baudouin}</option>
                <option value="volvoPenta">{modelLabels[language].volvoPenta}</option>
              </select>
            </div>

            <div className="form-group">
              <select
                value={formData.model}
                onChange={(e) => setFormData({...formData, model: e.target.value})}
                required
                className="form-select"
                disabled={!selectedBrand}
              >
                <option value="">{modelLabels[language].selectModel}</option>
                {selectedBrand && selectedBrand in generatorModels && 
                  generatorModels[selectedBrand as keyof GeneratorModels].map((power: number) => (
                    <option key={power} value={power}>
                      {power} kVA
                    </option>
                  ))
                }
              </select>
            </div>

            <div className="form-group">
              <textarea
                placeholder="Your Message"
                value={formData.message}
                onChange={(e) => setFormData({...formData, message: e.target.value})}
                rows={4}
              />
            </div>
            <button type="submit" className="submit-btn">
              {translations.requestQuote}
            </button>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default RequestQuoteForm; 