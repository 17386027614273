import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Products from './components/Products/Products';
import Projects from './components/Projects/Projects';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import AboutPage from './pages/AboutPage';
import WhatsAppButton from './components/WhatsAppButton/WhatsAppButton';
import Footer from './components/Footer/Footer';
import PartnersSlider from './components/PartnersSlider/PartnersSlider';
import PartnersSection from './components/PartnersSection/PartnersSection';
import { LanguageProvider } from './context/LanguageContext';
import PurchasePage from './pages/PurchasePage';
import PurchaseButton from './components/PurchaseButton/PurchaseButton';
import { useLanguage } from './context/LanguageContext';
import ProductDetails from './pages/ProductDetails';

// Create a separate component for the home page content
const HomePage = () => {
  const { language } = useLanguage();
  
  return (
    <>
      <Hero />
      <Services />
      <Products />
      <PartnersSection language={language} />
      <PartnersSlider />
      <Projects />
      <Contact />
    </>
  );
};

// Separate ScrollToTop component
const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0); // Simple version that scrolls immediately
  }, [pathname]);

  return null;
};

// Wrap the main content in a component that uses the router hooks
const AppContent: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/groupe-electrogene" element={<PurchasePage />} />
        <Route path="/groupe-electrogene/:brand/:model" element={<ProductDetails />} />
      </Routes>
      <Footer />
      <PurchaseButton />
      <WhatsAppButton phoneNumber="213555023075" />
    </>
  );
};

const App = () => {
  return (
    <LanguageProvider>
      <div className="App">
        <Router>
          <AppContent />
        </Router>
      </div>
    </LanguageProvider>
  );
};

export default App;
