import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../context/LanguageContext';
import SectionTitle from '../components/SectionTitle';
import { FaHandshake, FaLightbulb, FaTools, FaUsers, FaChartLine, FaShieldAlt } from 'react-icons/fa';
import './AboutPage.css';

interface ValueItem {
  icon: JSX.Element;
  title: string;
  description: string;
}

const translations = {
  en: {
    title: "About GMI Algérie",
    subtitle: "Leading Power Generation Solutions Since 1999",
    history: {
      title: "Our History",
      content: "Founded in 1999, GMI Algérie brings over 25 years of expertise in power generation solutions. Our extensive experience, combined with a nationwide service network and guaranteed after-sales support, has established us as a leading provider in Algeria. We've built our reputation on technical excellence, reliable service, and a deep understanding of industrial power needs."
    },
    mission: {
      title: "Our Mission",
      content: "Our mission is to deliver comprehensive power solutions backed by guaranteed after-sales service. With a team of certified technicians, 24/7 support, and a commitment to excellence, we ensure reliable power generation for businesses across Algeria. We maintain extensive spare parts inventory and provide rapid response maintenance services to minimize any downtime."
    },
    values: {
      title: "Our Values",
      items: [
        {
          icon: <FaHandshake />,
          title: "Trust & Reliability",
          description: "Building lasting relationships through dependable service and honest communication"
        },
        {
          icon: <FaLightbulb />,
          title: "Innovation",
          description: "Continuously evolving and implementing cutting-edge power solutions"
        },
        {
          icon: <FaTools />,
          title: "Excellence",
          description: "Maintaining the highest standards in all our products and services"
        },
        {
          icon: <FaUsers />,
          title: "Customer Focus",
          description: "Putting our clients' needs first and providing personalized solutions"
        },
        {
          icon: <FaChartLine />,
          title: "Performance",
          description: "Delivering optimal results and exceeding expectations"
        },
        {
          icon: <FaShieldAlt />,
          title: "Quality",
          description: "Ensuring premium standards in every aspect of our operations"
        }
      ] as ValueItem[]
    }
  },
  fr: {
    title: "À Propos de GMI Algérie",
    subtitle: "Solutions de Production d'Énergie depuis 1999",
    history: {
      title: "Notre Histoire",
      content: "Fondée en 1999, GMI Algérie cumule plus de 25 ans d'expertise dans les solutions de production d'énergie. Notre vaste expérience, associée à un réseau de service national et un support après-vente garanti, nous a établis comme un fournisseur leader en Algérie. Nous avons bâti notre réputation sur l'excellence technique, un service fiable et une profonde compréhension des besoins énergétiques industriels."
    },
    mission: {
      title: "Notre Mission",
      content: "Notre mission est de fournir des solutions énergétiques complètes soutenues par un service après-vente garanti. Avec une équipe de techniciens certifiés, un support 24/7 et un engagement envers l'excellence, nous assurons une production d'énergie fiable pour les entreprises à travers l'Algérie. Nous maintenons un stock important de pièces de rechange et fournissons des services de maintenance rapide pour minimiser tout temps d'arrêt."
    },
    values: {
      title: "Nos Valeurs",
      items: [
        {
          icon: <FaHandshake />,
          title: "Confiance & Fiabilité",
          description: "Construire des relations durables grâce à un service fiable et une communication honnête"
        },
        {
          icon: <FaLightbulb />,
          title: "Innovation",
          description: "Évoluer continuellement et mettre en œuvre des solutions énergétiques de pointe"
        },
        {
          icon: <FaTools />,
          title: "Excellence",
          description: "Maintenir les plus hauts standards dans tous nos produits et services"
        },
        {
          icon: <FaUsers />,
          title: "Focus Client",
          description: "Prioriser les besoins de nos clients et fournir des solutions personnalisées"
        },
        {
          icon: <FaChartLine />,
          title: "Performance",
          description: "Fournir des résultats optimaux et dépasser les attentes"
        },
        {
          icon: <FaShieldAlt />,
          title: "Qualité",
          description: "Assurer des standards premium dans tous les aspects de nos opérations"
        }
      ] as ValueItem[]
    }
  },
  ar: {
    title: "حول جي إم آي الجزائر",
    subtitle: "حلول توليد الطاقة الرائدة منذ 1998",
    history: {
      title: "تاريخنا",
      content: "تأسست جي إم آي الجزائر عام 1998، وتمتلك أكثر من 25 عاماً من الخبرة في حلول توليد الطاقة. خبرتنا الواسعة، إلى جانب شبكة خدمة وطنية وخدمة ما بعد البيع المضمونة، جعلتنا مزوداً رائداً في الجزائر. بنينا سمعتنا على التميز التقني والخدمة الموثوقة والفهم العميق لاحتياجات الطاقة الصناعية."
    },
    mission: {
      title: "مهمتنا",
      content: "مهمتنا هي تقديم حلول طاقة شاملة مدعومة بخدمة ما بعد البيع المضمونة. مع فريق من الفنيين المعتمدين، ودعم على مدار الساعة طوال أيام الأسبوع، والتزام بالتميز، نضمن توليد طاقة موثوق للشركات في جميع أنحاء الجزائر. نحتفظ بمخزون كبير من قطع الغيار ونقدم خدمات صيانة سريعة لتقليل أي توقف عن العمل."
    },
    values: {
      title: "قيمنا",
      items: [
        {
          icon: <FaHandshake />,
          title: "الثقة والموثوقية",
          description: "بناء علاقات دائمة من خلال الخدمة الموثوقة والتواصل الصادق"
        },
        {
          icon: <FaLightbulb />,
          title: "الابتكار",
          description: "التطور المستمر وتنفيذ حلول الطاقة المتطورة"
        },
        {
          icon: <FaTools />,
          title: "التميز",
          description: "الحفاظ على أعلى المعايير في جميع منتجاتنا وخدماتنا"
        },
        {
          icon: <FaUsers />,
          title: "التركيز على العملاء",
          description: "وضع احتياجات عملائنا أولاً وتقديم حلول مخصصة"
        },
        {
          icon: <FaChartLine />,
          title: "الأداء",
          description: "تحقيق النتائج المثلى وتجاوز التوقعات"
        },
        {
          icon: <FaShieldAlt />,
          title: "الجودة",
          description: "ضمان معايير متميزة في كل جوانب عملياتنا"
        }
      ] as ValueItem[]
    }
  }
};

const AboutPage: React.FC = () => {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <div className={`about-page ${language === 'ar' ? 'rtl' : ''}`}>
      <SectionTitle 
        title={t.title}
        subtitle={t.subtitle}
      />
      
      <div className="about-content">
        <motion.section 
          className="history-section"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2>{t.history.title}</h2>
          <p>{t.history.content}</p>
        </motion.section>

        <motion.section 
          className="mission-section"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2>{t.mission.title}</h2>
          <p>{t.mission.content}</p>
        </motion.section>

        <motion.section 
          className="values-section"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h2>{t.values.title}</h2>
          <div className="values-grid">
            {t.values.items.map((value, index) => (
              <motion.div 
                key={index}
                className="value-card"
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 * index }}
              >
                <div className="value-icon">{value.icon}</div>
                <h3>{value.title}</h3>
                <p>{value.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>
      </div>
    </div>
  );
};

export default AboutPage;
