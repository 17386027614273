import React from 'react';
import { motion } from 'framer-motion';
import { FaCogs, FaWhatsapp } from 'react-icons/fa';
import './SparePartsSection.css';

interface SparePartsSectionProps {
  language: 'en' | 'fr' | 'ar';
}

// Add the WhatsApp phone number here
const SPARE_PARTS_WHATSAPP = '213550404027'; // You can modify this number

const translations = {
  en: {
    title: 'Order Your Spare Parts Now!',
    description: 'Our genuine spare parts ensure the longevity of your equipment. Simplify maintenance by ordering the necessary parts right away.',
    button: 'Request Spare Parts'
  },
  fr: {
    title: 'Commandez vos pièces de rechange dès maintenant !',
    description: 'Nos pièces de rechange authentiques assurent la longévité de votre équipement. Simplifiez la maintenance en commandant dès à présent les pièces nécessaires.',
    button: 'Demander une pièce de rechange'
  },
  ar: {
    title: 'اطلب قطع الغيار الآن!',
    description: 'قطع الغيار الأصلية لدينا تضمن عمر أطول لمعداتك. قم بتبسيط الصيانة من خلال طلب القطع اللازمة على الفور.',
    button: 'طلب قطع غيار'
  }
};

const SparePartsSection: React.FC<SparePartsSectionProps> = ({ language }) => {
  const t = translations[language];

  const handleWhatsAppClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const message = encodeURIComponent(
      language === 'fr' 
        ? "Bonjour, je souhaite commander des pièces de rechange."
        : language === 'ar'
        ? "مرحباً، أود طلب قطع غيار."
        : "Hello, I would like to order spare parts."
    );
    
    const whatsappUrl = `https://wa.me/${SPARE_PARTS_WHATSAPP}?text=${message}`;
    window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={`spare-parts-section ${language === 'ar' ? 'rtl' : 'ltr'}`}>
      <motion.div 
        className="spare-parts-content"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="spare-parts-text">
          <h2>{t.title}</h2>
          <p>{t.description}</p>
          <motion.button 
            className="spare-parts-btn"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleWhatsAppClick}
          >
            <FaWhatsapp className="whatsapp-icon" />
            {t.button}
          </motion.button>
        </div>
        <div className="spare-parts-visual">
          <motion.div 
            className="cogs-animation"
            animate={{ 
              rotate: 360 
            }}
            transition={{ 
              duration: 20,
              repeat: Infinity,
              ease: "linear"
            }}
          >
            <FaCogs className="cogs-icon" />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default SparePartsSection; 