import React, { useState } from 'react';
import { useLanguage } from '../context/LanguageContext';
import ProductCard from '../components/ProductCard/ProductCard';
import RequestQuoteForm from '../components/RequestQuoteForm/RequestQuoteForm';
import { motion, AnimatePresence } from 'framer-motion';
import calqueImage from '../assets/images/purchase/calque1.png';
import SparePartsSection from '../components/SparePartsSection/SparePartsSection';
import PartnersSection from '../components/PartnersSection/PartnersSection';
import './PurchasePage.css';

const translations = {
  en: {
    title: 'Our Generator Sets',
    subtitle: 'Discover our wide range of generator sets with premium engines',
    requestQuote: 'Request a Quote',
    powerRange: 'Power Range',
    kVA: 'kVA',
    learnMore: 'Learn More',
    allProducts: 'All Products',
    baudouin: 'Baudouin',
    volvoPenta: 'Volvo Penta'
  },
  fr: {
    title: 'Nos Groupes Électrogènes',
    subtitle: 'Découvrez notre vaste gamme de groupes électrogènes équipés de moteurs de premier choix',
    requestQuote: 'Demander un Devis',
    powerRange: 'Puissance',
    kVA: 'kVA',
    learnMore: 'En Savoir Plus',
    allProducts: 'Tous les Produits',
    baudouin: 'Baudouin',
    volvoPenta: 'Volvo Penta'
  },
  ar: {
    title: 'مولداتنا الكهربائية',
    subtitle: 'اكتشف مجموعتنا الواسعة من المولدات الكهربائية المزودة بمحركات متميزة',
    requestQuote: 'طلب عرض سعر',
    powerRange: 'نطاق القدرة',
    kVA: 'ك.ف.أ',
    learnMore: 'اعرف المزيد',
    allProducts: 'جميع المنتجات',
    baudouin: 'بودوان',
    volvoPenta: 'فولفو بنتا'
  }
};

// First, let's define an interface for our product type
interface Product {
  id: string;
  image: string;
  model: string;
  power: string;
  brand: string;
}

// Define the products structure type
interface ProductsData {
  baudouin: Product[];
  volvoPenta: Product[];
}

// Move the products data into a constant with the correct type
const productsData: ProductsData = {
  baudouin: [
    {
      id: 'b1',
      image: calqueImage,
      model: '20 kVA',
      power: '4M06GE',
      brand: 'Baudouin'
    },
    {
      id: 'b2',
      image: calqueImage,
      model: '500 kVA',
      power: '4M11GE',
      brand: 'Baudouin'
    },
    {
      id: 'b3',
      image: calqueImage,
      model: '2000 kVA',
      power: '6M11GE',
      brand: 'Baudouin'
    },
    {
      id: 'b4',
      image: calqueImage,
      model: '2000 kVA',
      power: '6M16GE',
      brand: 'Baudouin'
    },
    {
      id: 'b5',
      image: calqueImage,
      model: '2000 kVA',
      power: '6M21GE',
      brand: 'Baudouin'
    },
    {
      id: 'b6',
      image: calqueImage,
      model: '2000 kVA',
      power: '6M33GE',
      brand: 'Baudouin'
    },
    {
      id: 'b7',
      image: calqueImage,
      model: '2000 kVA',
      power: '12M26GE',
      brand: 'Baudouin'
    },
    {
      id: 'b8',
      image: calqueImage,
      model: '2000 kVA',
      power: '12M33GE',
      brand: 'Baudouin'
    },
    {
      id: 'b9',
      image: calqueImage,
      model: '2000 kVA',
      power: '16M33GE',
      brand: 'Baudouin'
    },
  ],
  volvoPenta: [
    {
      id: 'v1',
      image: calqueImage,
      model: 'TAD5xxGE',
      power: 'TAD5xxGE',
      brand: 'Volvo Penta'
    },
    {
      id: 'v2',
      image: calqueImage,
      model: '400 kVA',
      power: 'TAD7xxGE',
      brand: 'Volvo Penta'
    },
    {
      id: 'v3',
      image: calqueImage,
      model: '800 kVA',
      power: 'TAD8xxGE',
      brand: 'Volvo Penta'
    },{
      id: 'v4',
      image: calqueImage,
      model: '800 kVA',
      power: 'TAD13xxGE',
      brand: 'Volvo Penta'
    },
    {
      id: 'v5',
      image: calqueImage,
      model: '800 kVA',
      power: 'TAD16xxGE',
      brand: 'Volvo Penta'
    },
    {
      id: 'v6',
      image: calqueImage,
      model: '800 kVA',
      power: 'TAD17xxGE',
      brand: 'Volvo Penta'
    },
  ]
};

const PurchasePage: React.FC = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const [activeCategory, setActiveCategory] = useState<'all' | 'baudouin' | 'volvoPenta'>('all');
  const [showAll, setShowAll] = useState(false);

  const getFilteredProducts = (): Product[] => {
    let filteredProducts: Product[];
    
    if (activeCategory === 'all') {
      filteredProducts = [...productsData.baudouin, ...productsData.volvoPenta];
    } else {
      filteredProducts = productsData[activeCategory];
    }
    
    return showAll ? filteredProducts : filteredProducts.slice(0, 5);
  };

  const handleShowMore = () => {
    setShowAll(true);
  };

  const handleCategoryChange = (category: 'all' | 'baudouin' | 'volvoPenta') => {
    setActiveCategory(category);
    setShowAll(false);
  };

  return (
    <div className={`purchase-page ${language === 'ar' ? 'rtl' : 'ltr'}`}>
      <div className="purchase-hero">
        <h1>{t.title}</h1>
        <p>{t.subtitle}</p>
      </div>

      <div className="category-switcher">
        <button 
          className={`category-btn ${activeCategory === 'all' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('all')}
        >
          {t.allProducts}
        </button>
        <button 
          className={`category-btn ${activeCategory === 'baudouin' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('baudouin')}
        >
          {t.baudouin}
        </button>
        <button 
          className={`category-btn ${activeCategory === 'volvoPenta' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('volvoPenta')}
        >
          {t.volvoPenta}
        </button>
      </div>

      <div className="category-info">
        {activeCategory === 'baudouin' && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="range-info"
          >
            20 kVA - 4000 kVA
          </motion.p>
        )}
        {activeCategory === 'volvoPenta' && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="range-info"
          >
            85 kVA - 800 kVA
          </motion.p>
        )}
      </div>
      
      <AnimatePresence mode='wait'>
        <motion.div 
          className="products-grid"
          key={activeCategory}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          {getFilteredProducts().map((product: Product) => (
            <ProductCard 
              key={product.id}
              product={product}
              translations={t}
            />
          ))}
        </motion.div>
      </AnimatePresence>

      {!showAll && getFilteredProducts().length === 5 && (
        <motion.button
          className="show-more-btn"
          onClick={handleShowMore}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {language === 'fr' ? 'Voir Plus' : language === 'ar' ? 'عرض المزيد' : 'Show More'}
        </motion.button>
      )}

      <RequestQuoteForm translations={t} />
      <SparePartsSection language={language} />
      <PartnersSection language={language} />
    </div>
  );
};

export default PurchasePage; 