import React from 'react';
import styled from 'styled-components';
import { FaWhatsapp } from 'react-icons/fa';
import { useLanguage } from '../../context/LanguageContext';

const WhatsAppWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9999;
  animation: floatIn 0.5s ease-out forwards;
  pointer-events: auto;
  
  @keyframes floatIn {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const WhatsAppLink = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background: #25D366;
  color: white !important;
  text-decoration: none !important;
  border-radius: 50px;
  box-shadow: 0 4px 12px rgba(37, 211, 102, 0.3);
  transition: all 0.3s ease;
  font-family: 'Arial', sans-serif;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(37, 211, 102, 0.4);
    background: #22c35e;
    color: white !important;
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 12px rgba(37, 211, 102, 0.3);
  }

  @media (max-width: 768px) {
    padding: 12px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    justify-content: center;

    span {
      display: none;
    }
  }
`;

const Icon = styled(FaWhatsapp)`
  font-size: 24px;
  margin-right: 8px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    margin: 0;
    font-size: 28px;
  }
`;

const ButtonText = styled.span`
  font-size: 16px;
  white-space: nowrap;
  
  [dir='rtl'] & {
    margin-right: 8px;
    margin-left: 0;
  }
`;

interface WhatsAppButtonProps {
  phoneNumber: string;
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ phoneNumber }) => {
  const { language } = useLanguage();

  const translations = {
    buttonText: {
      en: 'Chat with us',
      fr: 'Discutez avec nous',
      ar: 'تحدث معنا'
    }
  };

  const whatsappUrl = `https://wa.me/${phoneNumber}`;

  return (
    <WhatsAppWrapper>
      <WhatsAppLink 
        href={whatsappUrl}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Chat on WhatsApp"
      >
        <Icon />
        <ButtonText>{translations.buttonText[language]}</ButtonText>
      </WhatsAppLink>
    </WhatsAppWrapper>
  );
};

export default WhatsAppButton;
