export const lightTheme = {
  background: '#FFFFFF',
  text: '#333333',
  navBackground: 'rgba(255, 255, 255, 0.95)',
  cardBackground: '#F5F5F5',
  shadowColor: 'rgba(0, 0, 0, 0.1)',
};

export const darkTheme = {
  background: '#1A1A1A',
  text: '#FFFFFF',
  navBackground: 'rgba(26, 26, 26, 0.95)',
  cardBackground: '#2A2A2A',
  shadowColor: 'rgba(0, 0, 0, 0.3)',
};
