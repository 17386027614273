import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    --primary-green: #008751;
    --primary-red: #CE1126;
    --primary-white: #FFFFFF;
    --text-primary: ${({ theme }) => theme.text};
    --bg-primary: ${({ theme }) => theme.background};
    --transition: all 0.3s ease-in-out;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Poppins', sans-serif;
    background: var(--bg-primary);
    color: var(--text-primary);
    transition: var(--transition);
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    cursor: pointer;
    border: none;
    outline: none;
  }

  .section {
    padding: 80px 0;
    @media (max-width: 768px) {
      padding: 60px 0;
    }
  }

  .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
`;
