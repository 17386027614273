import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaIndustry, FaTools, FaBolt, FaCog } from 'react-icons/fa';
import { useLanguage } from '../../context/LanguageContext';
import SectionTitle from '../SectionTitle';

const ServicesSection = styled.section`
  padding: 5rem calc((100vw - 1200px) / 2);
  background: var(--bg-primary);
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(46, 125, 50, 0.1) 0%, transparent 70%);
    border-radius: 50%;
    z-index: 0;
  }

  &::before {
    top: -100px;
    left: -100px;
  }

  &::after {
    bottom: -100px;
    right: -100px;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
`;

const ServiceCard = styled(motion.div)`
  background: ${({ theme }) => theme.cardBackground};
  padding: 2.5rem 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px ${({ theme }) => theme.shadowColor};
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  text-align: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #2E7D32, #4CAF50);
    transform: scaleX(0);
    transition: transform 0.3s ease;
    transform-origin: left;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px ${({ theme }) => theme.shadowColor};

    &::before {
      transform: scaleX(1);
    }
  }
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: var(--primary-green);
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;

  ${ServiceCard}:hover & {
    transform: scale(1.1) rotate(5deg);
  }
`;

const ServiceTitle = styled.h3`
  color: var(--text-primary);
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
  padding-bottom: 0.5rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 2px;
    background: var(--primary-green);
    transition: width 0.3s ease;
  }

  ${ServiceCard}:hover &::after {
    width: 80px;
  }
`;

const ServiceDescription = styled.p`
  color: var(--text-primary);
  line-height: 1.6;
  opacity: 0.9;
`;

const Services = () => {
  const { language } = useLanguage();
  const t = {
    title: {
      en: 'Our Services',
      fr: 'Nos Services',
      ar: 'خدماتنا'
    },
    subtitle: {
      en: 'Comprehensive power solutions for your needs',
      fr: 'Des solutions énergétiques complètes pour vos besoins',
      ar: 'حلول طاقة شاملة لاحتياجاتك'
    },
    services: [
      {
        icon: <FaCog />,
        title: {
          en: 'Generator Sets',
          fr: 'Groupes Électrogènes',
          ar: 'المولدات الكهربائية'
        },
        description: {
          en: 'Installation and maintenance of generator sets for reliable and continuous power supply.',
          fr: 'Installation et maintenance de groupes électrogènes pour une alimentation électrique fiable et continue.',
          ar: 'تركيب وصيانة المولدات الكهربائية لتوفير إمداد كهربائي موثوق ومستمر.'
        }
      },
      {
        icon: <FaBolt />,
        title: {
          en: 'Energy Solutions',
          fr: 'Solutions Énergétiques',
          ar: 'حلول الطاقة'
        },
        description: {
          en: 'Energy consumption optimization and innovative electrical distribution solutions.',
          fr: 'Optimisation de la consommation d\'énergie et solutions de distribution électrique innovantes.',
          ar: 'تحسين استهلاك الطاقة وحلول توزيع الكهرباء المبتكرة.'
        }
      },
      {
        icon: <FaIndustry />,
        title: {
          en: 'Industrial Production',
          fr: 'Production Industrielle',
          ar: 'الإنتاج الصناعي'
        },
        description: {
          en: 'Manufacturing of electrical components and customized industrial solutions.',
          fr: 'Fabrication de composants électriques et solutions sur mesure pour l\'industrie.',
          ar: 'تصنيع المكونات الكهربائية والحلول الصناعية المخصصة.'
        }
      },
      {
        icon: <FaTools />,
        title: {
          en: 'Maintenance & Support',
          fr: 'Maintenance & Support',
          ar: 'الصيانة والدعم'
        },
        description: {
          en: 'Preventive and corrective maintenance service, 24/7 technical support.',
          fr: 'Service de maintenance préventive et corrective, support technique 24/7.',
          ar: 'خدمة الصيانة الوقائية والتصحيحية، والدعم الفني على مدار الساعة.'
        }
      }
    ]
  };

  return (
    <ServicesSection>
      <SectionTitle 
        title={t.title[language]}
        subtitle={t.subtitle[language]}
      />
      <ServicesGrid>
        {t.services.map((service, index) => (
          <ServiceCard
            key={index}
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ 
              duration: 0.5, 
              delay: index * 0.2,
              ease: [0.645, 0.045, 0.355, 1.000]
            }}
          >
            <IconWrapper>{service.icon}</IconWrapper>
            <ServiceTitle>{service.title[language]}</ServiceTitle>
            <ServiceDescription>{service.description[language]}</ServiceDescription>
          </ServiceCard>
        ))}
      </ServicesGrid>
    </ServicesSection>
  );
};

export default Services;
