import React from 'react';
import { motion } from 'framer-motion';
import './SectionTitle.css';

interface SectionTitleProps {
  title: string;
  subtitle?: string;
  light?: boolean;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, subtitle, light }) => {
  const words = title.split(' ');
  
  return (
    <div className={`modern-section-title ${light ? 'light' : ''}`}>
      <div className="title-wrapper">
        {words.map((word, i) => (
          <motion.span
            key={i}
            className="word-wrapper"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.8,
              delay: i * 0.2,
              ease: [0.2, 0.65, 0.3, 0.9]
            }}
            viewport={{ once: true }}
          >
            {word}{' '}
          </motion.span>
        ))}
      </div>
      {subtitle && (
        <motion.p
          className="modern-subtitle"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: words.length * 0.2 }}
          viewport={{ once: true }}
        >
          {subtitle}
        </motion.p>
      )}
      <motion.div 
        className="title-decoration"
        initial={{ width: 0 }}
        whileInView={{ width: '80px' }}
        transition={{ duration: 0.8, delay: 0.5 }}
        viewport={{ once: true }}
      />
    </div>
  );
};

export default SectionTitle; 