import React, { createContext, useState, useContext, ReactNode } from 'react';

type Language = 'en' | 'ar' | 'fr';

interface TranslationKeys {
  'nav.home': string;
  'nav.about': string;
  'nav.services': string;
  'nav.projects': string;
  'nav.products': string;
  'nav.contact': string;
  'hero.title': string;
  'hero.subtitle': string;
  'hero.cta': string;
  'services.title': string;
  'services.description': string;
  'products.title': string;
  'products.description': string;
  'projects.title': string;
  'projects.description': string;
  'contact.title': string;
  'contact.description': string;
  'partners.title': string;
}

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  t: (key: keyof TranslationKeys) => string;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

// Define translations for all languages
const translations: Record<Language, TranslationKeys> = {
  en: {
    // Navigation
    'nav.home': 'Home',
    'nav.about': 'About',
    'nav.services': 'Services',
    'nav.projects': 'Projects',
    'nav.products': 'Products',
    'nav.contact': 'Contact',
    
    // Hero Section
    'hero.title': 'Welcome to GMI ALGERIE',
    'hero.subtitle': 'Your Partner in Industrial Solutions',
    'hero.cta': 'Learn More',
    
    // Services Section
    'services.title': 'Our Services',
    'services.description': 'We provide comprehensive industrial solutions',
    
    // Products Section
    'products.title': 'Our Products',
    'products.description': 'Discover our range of quality products',
    
    // Projects Section
    'projects.title': 'Our Projects',
    'projects.description': 'View our successful implementations',
    
    // Contact Section
    'contact.title': 'Contact Us',
    'contact.description': 'Get in touch with our team',

    // Partners Section
    'partners.title': 'They Trust Us',
  },
  fr: {
    // Navigation
    'nav.home': 'Accueil',
    'nav.about': 'À Propos',
    'nav.services': 'Services',
    'nav.projects': 'Projets',
    'nav.products': 'Produits',
    'nav.contact': 'Contact',
    
    // Hero Section
    'hero.title': 'Bienvenue à GMI ALGERIE',
    'hero.subtitle': 'Votre Partenaire en Solutions Industrielles',
    'hero.cta': 'En Savoir Plus',
    
    // Services Section
    'services.title': 'Nos Services',
    'services.description': 'Nous fournissons des solutions industrielles complètes',
    
    // Products Section
    'products.title': 'Nos Produits',
    'products.description': 'Découvrez notre gamme de produits de qualité',
    
    // Projects Section
    'projects.title': 'Nos Projets',
    'projects.description': 'Consultez nos réalisations',
    
    // Contact Section
    'contact.title': 'Contactez-nous',
    'contact.description': 'Prenez contact avec notre équipe',

    // Partners Section
    'partners.title': 'Qui Nous Ont Fait Confiance',
  },
  ar: {
    // Navigation
    'nav.home': 'الرئيسية',
    'nav.about': 'حول',
    'nav.services': 'خدمات',
    'nav.projects': 'مشاريع',
    'nav.products': 'منتجات',
    'nav.contact': 'اتصل بنا',
    
    // Hero Section
    'hero.title': 'مرحباً بكم في GMI ALGERIE',
    'hero.subtitle': 'شريككم في الحلول الصناعية',
    'hero.cta': 'اعرف المزيد',
    
    // Services Section
    'services.title': 'خدماتنا',
    'services.description': 'نقدم حلولاً صناعية شاملة',
    
    // Products Section
    'products.title': 'منتجاتنا',
    'products.description': 'اكتشف مجموعة منتجاتنا عالية الجودة',
    
    // Projects Section
    'projects.title': 'مشاريعنا',
    'projects.description': 'شاهد تنفيذاتنا الناجحة',
    
    // Contact Section
    'contact.title': 'اتصل بنا',
    'contact.description': 'تواصل مع فريقنا',

    // Partners Section
    'partners.title': 'من يثقون بنا',
  }
};

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<Language>('fr');

  const t = (key: keyof TranslationKeys): string => {
    return translations[language][key];
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
